@font-face {
    font-family:  'HelveticaNeue';
    font-weight:  400;
    font-display: swap;
    src:          url('../public/Fonts/de7da99e-0f77-4410-b512-b71c48f2fff5.eot?#iefix');
    src:          url('../public/Fonts/de7da99e-0f77-4410-b512-b71c48f2fff5.eot?#iefix') format('eot'),
    url('../public/Fonts/c83a2632-2ed7-4931-8668-d9d2135bd9f5.woff') format('woff'),
    url('../public/Fonts/104a9eb8-8293-4ea8-92ad-b14cfe0913ae.ttf') format('truetype'),
    url('../public/Fonts/0c8a90a5-fcf0-4339-a16c-edfdac3408e8.svg#0c8a90a5-fcf0-4339-a16c-edfdac3408e8') format('svg');
}


@font-face {
    font-family:  'HelveticaNeue';
    font-weight:  500;
    font-display: swap;
    src:          url('../public/Fonts/de8ea7a9-5466-4dc7-b7ab-af0e574abc59.eot?#iefix');
    src:          url('../public/Fonts/de8ea7a9-5466-4dc7-b7ab-af0e574abc59.eot?#iefix') format('eot'),
    url('../public/Fonts/b9737fe6-d319-40cb-86ee-6ba0882bd5c2.woff') format('woff'),
    url('../public/Fonts/2e97165a-0bc2-4d7b-932e-469416453152.ttf') format('truetype'),
    url('../public/Fonts/599c2c22-db49-4454-97d0-6bb81892c795.svg#599c2c22-db49-4454-97d0-6bb81892c795') format('svg');
}


@font-face {
    font-family:  'HelveticaNeue';
    font-weight:  700;
    font-display: swap;
    src:          url('../public/Fonts/ec713153-c8f0-429b-8fc6-0a06c5d1b467.eot?#iefix');
    src:          url('../public/Fonts/ec713153-c8f0-429b-8fc6-0a06c5d1b467.eot?#iefix') format('eot'),
    url('../public/Fonts/8786c854-ea64-4c4b-a066-ac1b2d313e19.woff') format('woff'),
    url('../public/Fonts/595dbd97-9900-40b8-b7bf-907c9f1c3ff0.ttf') format('truetype'),
    url('../public/Fonts/2887358e-53f5-4ec0-b3f8-66b31480cf9d.svg#2887358e-53f5-4ec0-b3f8-66b31480cf9d') format('svg');
}
