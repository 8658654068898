@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";
@import "typo";
@import "draftJS";
@import "cardContentStyles";

* {
    text-rendering:              optimizeLegibility;
    -webkit-font-smoothing:      antialiased;
    -moz-osx-font-smoothing:     grayscale;
    -webkit-tap-highlight-color: transparent;
    outline: none;
}

.min-safe-h-screen {
    /* equivalent to 100dvh in major browsers */
    min-height: calc(
                        100vh - env(safe-area-inset-bottom, 0) - env(safe-area-inset-top, 0)
                );
}

@supports (-webkit-touch-callout: none) {
    .min-safe-h-screen {
        /* for ios safari 15, safe-area-inset-bottom is 0, so a special fix apply here */
        min-height: -webkit-fill-available;
    }
}

a {
    &:visited {
    }

    &:focus {
    }
}

input:focus,
select:focus,
textarea:focus,
button:focus {
}

@layer components {
}

@layer utilities {
    body {
        @apply h-full;
    }
}

.Toastify {
    z-index: 999999;

    > div {
        z-index: 99999;
    }
}


[aria-controls='ReactQueryDevtoolsPanel'] {
    position:  absolute !important;
    bottom:    50px !important;
    transform: scale(0.5);
}

p {
    @apply font-body;
}
