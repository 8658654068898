.public-DraftEditor-content {

  > div {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }

}

.DraftEditor-root {

  @apply relative h-full flex w-full p-0;

  .public-DraftEditorPlaceholder-root {

    @apply absolute;

    .public-DraftEditorPlaceholder-inner {
      @apply break-words;
      white-space: normal !important;

    }

    > div {
      @apply text-neutral-500 text-sm ;
    }
  }

  .DraftEditor-editorContainer {
    @apply h-max min-h-[100px] relative flex w-full focus:bg-primary/10 transition-all;
  }
}


.cardContentStyles {

  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  line-height: 20px;
  word-wrap: break-word; // Wrap long words onto the next line
  overflow-wrap: break-word;
  white-space: normal;


  ul {
    list-style: none; // remove the default bullet points
    padding-left: 2rem;

    display: flex;
    flex-direction: column;
    gap: 8px;


    li {
      position: relative; // create a new context for positioning

      &::before { // add your own 'bullet' point
        content: '';
        position: absolute;
        top: 4px;
        left: -22px; // adjust as necessary
        width: 10px; // size of square
        height: 10px;
        background-color: #00448b;
      }
    }
  }

  // Links
  a {
    @apply text-primary font-medium hover:text-primary-dark transition-all underline;
  }

  strong {
    @apply font-medium;
  }

}
